import React, { useState } from "react";
import { Grid, Tabs, Tab, Button } from "@mui/material";

import OGP from "@/components/OGP";

import ArticleCard from "@/components/ArticleCard";

import axios from "axios";

import ScrollFix from "@/components/StickyControll";

import AboutPage from "@/components/About";
import _ from "lodash";
import InfiniteScroller from "@/components/infinite-scroll";

function Home({ latestArticles, trendingArticles }: any) {
  const [latest_articles, setLatest] = useState(latestArticles);
  const [trending_articles, setTrending] = useState(trendingArticles);

  const [tab, setTab] = useState<any>(0);
  const handleTab = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const resetScroll = () => {
    var objDiv = document.getElementById("above") as HTMLElement;
    window.scrollTo({
      top: objDiv.offsetTop + objDiv.offsetHeight - 60,
      behavior: "smooth",
    });
  };
  const OptionTab = () => {
    return (
      <Tabs
        value={tab}
        onChange={handleTab}
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={<b>Latest</b>}
          onClick={() => {
            resetScroll();
          }}
        />
        <Tab
          label={<b>Trending</b>}
          onClick={() => {
            resetScroll();
          }}
        />
      </Tabs>
    );
  };
  const [latestLoadMore, setLatestLoadMore] = useState(false);
  const [latestIsLast, setLatestIsLast] = useState(false);

  const [latestNextPage, setLatestNextPage] = useState(2);
  const latestOnLoad = () => {
    axios
      .get(
        `${process.env.BACKEND_URL}/v1/notebox/latest?page=${latestNextPage}`
      )
      .then((res) => {
        if (res.data.message) {
          setLatestIsLast(true);
        } else {
          var new_articles = _.cloneDeep(latest_articles);
          const fetchedNotes = res.data.filter(
            (article) => !article.author.is_evil
          );
          new_articles = new_articles.concat(fetchedNotes);
          setLatest(new_articles);
          setLatestNextPage(latestNextPage + 1);
        }
        setLatestLoadMore(false);
      })
      .catch((e) => console.log(e));
  };
  const latestLoadHandler = () => {
    setLatestLoadMore(true);
  };
  const [trendingLoadMore, setTrendingLoadMore] = useState(false);
  const [trendingIsLast, settrendingIsLast] = useState(false);

  const [trendingNextPage, setTrendingNextPage] = useState(2);
  const trendingOnLoad = () => {
    axios
      .get(
        `${process.env.BACKEND_URL}/v1/notebox/trending?page=${trendingNextPage}`
      )
      .then((res) => {
        if (res.data.message) {
          settrendingIsLast(true);
        } else {
          var new_articles = _.cloneDeep(trending_articles);
          const fetchedNotes = res.data.filter(
            (article) => !article.author.is_evil
          );
          new_articles = new_articles.concat(fetchedNotes);
          setTrending(new_articles);
          setTrendingNextPage(trendingNextPage + 1);
        }
        setTrendingLoadMore(false);
      })
      .catch((e) => console.log(e));
  };
  const trendingLoadHandler = () => {
    setTrendingLoadMore(true);
  };
  return (
    <>
      <OGP isRoot={true}/>
      {/* service explanation, impression */}

      <div className="relative" id="above">
        <AboutPage />
      </div>

      {/* tab */}
      <ScrollFix>
        <Grid
          container
          className="border-solid border-b border-blue-50 dark:border-gray-700"
          style={{
            paddingBottom: "0px",
            paddingTop: "0px",
          }}
        >
          <Grid
            item
            md={3}
            xs={false}
            style={{
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
          ></Grid>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
          >
            <div>{OptionTab()}</div>
          </Grid>
        </Grid>
      </ScrollFix>
      {/* latest, trending, recommendation */}
      <div className="pt-0 md:pt-6 dark:bg-gray-800">
        <Grid container>
          <Grid item md={3} xs={false}></Grid>
          <Grid item md={6} xs={12}>
            <div className="w-full">
              {tab === 0 ? (
                <>
                  <InfiniteScroller
                    onLoad={latestOnLoad}
                    hasMore={latestLoadMore && !latestIsLast}
                  >
                    {latest_articles.map((item: any, idx: any) => {
                      const key = "latest" + idx;
                      return (
                        <div key={key} className="w-full">
                          <ArticleCard data={item} />
                        </div>
                      );
                    })}
                  </InfiniteScroller>
                </>
              ) : (
                <>
                  <InfiniteScroller
                    onLoad={trendingOnLoad}
                    hasMore={trendingLoadMore && !trendingIsLast}
                  >
                    {trending_articles.map((item: any, idx: any) => {
                      const key = "trending" + idx;
                      return (
                        <div key={key} className="w-full">
                          <ArticleCard data={item} />
                        </div>
                      );
                    })}
                  </InfiniteScroller>
                </>
              )}
              <div className="text-center">
                <Button
                  className="rounded-full light-shadow mr-3"
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={tab === 0 ? latestLoadHandler : trendingLoadHandler}
                >
                  さらに読み込む
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export const getStaticProps = async () => {
  const res = await fetch(
    `${process.env.BACKEND_URL}/v1/notebox/latest?page=1`
  );
  const data = await res.json();
  const notes = data;
  const res2 = await fetch(
    `${process.env.BACKEND_URL}/v1/notebox/trending?page=1`
  );
  const data2 = await res2.json();
  const notes2 = data2;

  const latestArticles = notes.filter((article) => !article.author.is_evil);
  const trendingArticles = notes2.filter((article) => !article.author.is_evil);
  return {
    props: { latestArticles, trendingArticles },
    revalidate: 10,
  };
};

export default Home;
