import InfiniteScroll from "react-infinite-scroller";
import { CircularProgress } from "@mui/material";

export default function InfiniteScroller({ children, onLoad, hasMore }: any) {
  //ロード中に表示する項目
  const loader = (
    <div className="text-center py-3">
      <CircularProgress color="secondary" />
    </div>
  );

  return (
    <InfiniteScroll
      loadMore={onLoad} //項目を読み込む際に処理するコールバック関数
      hasMore={hasMore} //読み込みを行うかどうかの判定
      loader={loader} // components to show while loading
    >
      {children} {/* 無限スクロールで表示する項目 */}
    </InfiniteScroll>
  );
}
