import Paragraph from "@/components/Paragraph";
import { Button } from "@mui/material";
import { useAppState } from "@/contexts/AppProvider";

export default function AboutPage() {
  const [state, dispatch] = useAppState();
  const handleClickOpen = () => {
    dispatch({ type: "setLoginModal", payload: true });
  };

  return (
    <div className="about">
      <div className="relative overflow-hidden">
        <div className="area">
          <ul className="hexagons">
            <li className="hidden md:block"></li>
            <li></li>
            <li className="hidden md:block"></li>
            <li></li>
            <li className="hidden md:block"></li>
            <li></li>
            <li className="hidden md:block"></li>
            <li></li>
            <li className="hidden md:block"></li>
            <li></li>
          </ul>
        </div>
        <Paragraph className="pb-8 z-10" md={6}>
          {/* animiated background */}

          <div className="pt-12">
            <h1 className="text-5xl md:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-pink-500">
              <b>
                A Community For<br />
                IT Engineers
              </b>
            </h1>
            <div className="text-lg pt-8">
              <b>情報を共有して、お手軽に収益化しましょう。</b>
            </div>

            <div className="pt-10 pb-2">
              {state.fetched && (
                <>
                  {!state.userInfo && (
                    <Button
                      className="rounded-full light-shadow text-white mr-3 my-3"
                      color="secondary"
                      variant="contained"
                      size="large"
                      onClick={handleClickOpen}
                    >
                      <b>始める</b>
                    </Button>
                  )}
                  <a
                    href="https://it.commutty.com/CommuttyIT/articles/d90b9c0127ae468288e34f54c675abab"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="py-3"
                  >
                    <Button
                      className="rounded-full light-shadow mr-3"
                      color="primary"
                      variant="outlined"
                      size="large"
                    >
                      <b>詳しく</b>
                    </Button>
                  </a>
                  <a
                    href="https://magicode.io/ads/about"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="py-3"
                  >
                    <Button
                      className="rounded-full light-shadow"
                      color="primary"
                      variant="outlined"
                      size="large"
                    >
                      <b>広告掲載はこちらから</b>
                    </Button>
                  </a>
                </>
              )}
            </div>
            <div className="pt-2 pb-10">
            {state.fetched && (
                <>
                  <a
                    href="https://it.commutty.com/CommuttyIT/articles/2bc23c03402645f5945d30223cdcd80b"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="py-3"
                  >
                    <Button
                      className="rounded light-shadow"
                      color="primary"
                      variant="outlined"
                      size="large"
                    >
                      <b>運営会社変更のお知らせ</b>
                    </Button>
                  </a>
                </>
              )}

            </div>
          </div>
        </Paragraph>
      </div>
    </div>
  );
}
